import { find } from 'utils/dom'

export default function Header() {
	const spa = find("[js-page=home_spa]")
	if (!spa) return

	const btnClick = document.querySelector('.btn-ver-mais')
	const popUp = document.querySelector('.pop-up1_wrapper')
	const btnClose = document.querySelector('.btn-close')

	btnClick.addEventListener('click', (e) => { popUp.classList.add('pop-up-hidden') })

	btnClose.addEventListener('click', () => { popUp.classList.remove('pop-up-hidden') })
}
