import { find } from 'utils/dom'

export default function Header() {
	const menu = find("#menu")
	if (!menu) return

	document.addEventListener('DOMContentLoaded', function () {
		function updateLanguageSelector(wrapperClass) {
			const interval = setInterval(() => {
				const selectElement = document.querySelector(`${wrapperClass} .gt_selector`);
				if (selectElement) {
					clearInterval(interval);
					const options = selectElement.querySelectorAll('option');
					if (options.length > 0) {
						selectElement.remove(0);
					}
					if (options.length > 1) {
						options[1].textContent = 'BR';
					}
					if (options.length > 2) {
						options[2].textContent = 'ES';
					}
					if (options.length > 3) {
						options[3].textContent = 'EN';
					}
					selectElement.selectedIndex = 0;
				}
			}, 100);
		}
		updateLanguageSelector('.gtranslate_wrapper');
	});

	document.addEventListener('DOMContentLoaded', function () {
		const tabMenu = document.querySelector('.tabs-menu-3');
		if (tabMenu && window.innerWidth <= 768) {
			gsap.registerPlugin(Draggable);
			Draggable.create(tabMenu, {
				type: 'x',
				bounds: tabMenu.parentElement,
				inertia: true,
				edgeResistance: 0.75,
				onDragEnd: function () { }
			});
		}
	});

	window.addEventListener('scroll', function () {
		const menu = document.getElementById('menu');
		const alturaTela = window.innerHeight;
		const home = document.querySelector('#Home')

		if (home) {
			if (window.scrollY > (alturaTela - 180)) {  // Mudar o valor de 50 conforme necessário
				menu.classList.add('scroll');
			} else {
				menu.classList.remove('scroll');
			}
		}
		else {
			if (window.scrollY > 300) {  // Mudar o valor de 50 conforme necessário
				menu.classList.add('scroll');
			} else {
				menu.classList.remove('scroll');
			}
		}

	});
}
