import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import contato from './pages/Contato'
import Header from './pages/Header'
import Spa from './pages/Spa'
import PageLogin from "./pages/Login";
import EditorJS from "./modules/editorJS";

axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)

PageLogin()?.init()
EditorJS()
contato()
Header()
Spa()
