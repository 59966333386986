import axios from 'axios'
import showToastify from '../utils/toastify'
import 'toastify-js/src/toastify.css'
import { find } from 'utils/dom'
// import IMask from 'imask'

export default function PageContato() {
	const contato = find("[js-page='home']")
	if (!contato) return

	const contatoFormSubmit = document.querySelector('#form-contato')

	function showMessage(response) {
		const message = response.data ? response.data.message : response.message
		showToastify(message)
		contatoFormSubmit.reset()
		setTimeout(enabledBtn, 1000)
	}

	const btnSubmit = document.querySelector('#form-contato input[type=submit]')

	function disabledBtn() {
		btnSubmit.disabled = true
		btnSubmit.classList.add('disabled')
	}

	function enabledBtn() {
		btnSubmit.disabled = false
		btnSubmit.classList.remove('disabled')
	}

	contatoFormSubmit &&
		contatoFormSubmit.addEventListener('submit', function (event) {
			event.preventDefault()
			disabledBtn()

			const formData = new FormData()
			formData.append('nome', document.querySelector('#form-contato input[name=nome]').value)
			formData.append('email', document.querySelector('#form-contato input[name=email]').value)
			formData.append('mensagem', document.querySelector('#form-contato textarea[name=mensagem]').value)

			const url = '/fale-conosco/'
			axios
				.post(url, formData)
				.then((response) => showMessage(response))
				.catch((response) => showMessage(response))
		})
}
